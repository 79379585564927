* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* Start bootstrap settings */
.mb-1 {
	margin-bottom: 1rem !important;
}
.mb-2 {
	margin-bottom: 2rem !important;
}
.mb-3 {
	margin-bottom: 3rem !important;
}
.color-secondary {
	color: rgb(177, 177, 181);
	font-family: Roboto, sans-serif;
}

.margin-center {
	margin: 0 auto;
}

.card-modal-text {
	color: rgb(216, 216, 216);
}

.btn-container {
	margin-bottom: 10px;
}
.card-body-middle-container {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.cards-wrapper {
	min-height: 308px !important;
}
@media (min-width: 548px) {
	.card-head-title {
		min-height: 92px;
	}
}
@media (max-width: 552px) {
	/* .btn-container img {
		height: -webkit-fill-available;
		width: -webkit-fill-available;
	} */
}
.card-btn {
	max-width: 50px !important;
	max-height: 50px !important;
}
/* End bootstrap settings */

.h-line {
	background-color: #1e1e2f;
	opacity: 0.6;
	max-width: 100%;
	margin-bottom: 30px !important;
	margin-top: 20px !important;
	margin: 0 auto;
}

.modal {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5) !important;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100000;
}

.modal-content {
	width: 500px;
	background-color: #27293d;
	border-radius: 10px;
}

.modal-header,
.modal-footer {
	padding: 10px;
}

.modal-title {
	margin: 0;
	text-align: center;
	color: rgb(177, 177, 181);
}

.modal-subtitle {
	display: flex;
	flex-direction: column;
}
.modal-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.modal-body {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	padding: 10px;
	width: 80%;
	margin: 0 auto;
	width: 100%;
}

.modal-button {
	background-color: transparent;
	text-decoration: none;
	font-size: 16px;
	color: #1890ff;
	border: 1px solid #1890ff;
	text-align: center;
	padding: 10px 20px;
	max-width: 100px;
	border-radius: 5px;
}
.modal-button:hover {
	background-color: #1890ff;
	color: #fff;
}

.download-btn {
	background-color: #1890ff !important;
	text-decoration: none !important;
	font-family: inherit;
	font-size: 12px !important;
	color: #1890ff !important;
	border: 1px solid #1890ff !important;
	text-align: center !important;
	/* max-width: 60px !important; */
	border-radius: 3px !important;
	padding: 5px 10px !important;
	/* max-height: 45px !important; */
	margin-bottom: 20px !important;
	transition: all 0.5s;
	width: 50px;
	height: 40px;
}

/* .download-btn:hover {
	color: #fff !important;
	background-color: #1890ff !important;
} */

.btn-container {
	display: flex !important;
	justify-content: space-evenly !important;
	flex-wrap: wrap;
	width: 100% !important;
}
.product-item-wrapper .product-item .card-body {
	padding: 10px 15px !important;
}
.delete-btn {
	background-color: tomato !important;
	text-decoration: none !important;
	font-family: inherit;
	font-size: 12px !important;
	color: tomato !important;
	border: 1px solid tomato !important;
	text-align: center !important;
	border-radius: 3px !important;
	padding: 5px 10px !important;
	margin-bottom: 20px !important;
	transition: all 0.5s;
}

.notify-btn {
	padding: 8px 20px;
}

.dropdown .nav-link {
	color: pink;
}

.delete-btn img {
	width: 100%;
	height: 100%;
}
.download-btn img {
	width: 100%;
	height: 100%;
}
.delete-btn img {
	width: 100%;
	height: 100%;
}
@media (max-width: 1700px) {
	.delete-btn {
		margin: 5px !important;
	}
	.download-btn {
		margin: 5px !important;
	}
}
@media (max-width: 1400px) {
	.card-body-middle-container {
		display: flex !important;
		flex-direction: column !important;
	}
	.delete-btn {
		margin: 10px !important;
	}
	.download-btn {
		margin: 10px !important;
	}
}
@media (max-width: 1296px) {
	.cards-wrapper {
		display: flex !important;
		flex-direction: column !important;
	}
	.btn-container {
		display: flex !important;
		flex-wrap: wrap !important;
	}
	.delete-btn {
		margin: 5px !important;
	}
	.download-btn {
		margin: 5px !important;
	}
}
@media (min-width: 1400px) {
}

@media (max-width: 768px) {
	.btn-container {
		display: flex !important;
		align-items: stretch !important;
		justify-content: space-around !important;
	}

	.download-btn {
		margin: 5px !important;
	}
	.delete-btn {
		margin: 5px !important;
	}
}

@media (max-width: 528px) {
	.btn-container {
		display: flex !important;
		align-items: stretch !important;
		justify-content: space-around !important;
	}

	.download-btn {
		margin: 5px !important;
	}
	.delete-btn {
		margin: 5px !important;
	}

	.navbar-nav-right {
		display: flex !important;
		flex-direction: column !important;
		/* padding: 0.75rem; */
		align-items: center !important;
		justify-content: center !important;
	}
	.nav-profile {
		margin-left: 5px;
	}

	.navbar .navbar-menu-wrapper {
		height: auto;
	}

	.count-indicator {
		height: 60px !important;
	}

	.notify-btn {
		margin-bottom: 10px;
		padding: 8px 10px;
		max-width: 100px;
	}
	.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.page-body-wrapper {
		margin-top: 46px;
	}
}

@media (max-width: 780px) {
	.col-1,
	.col-2,
	.col-3,
	.tab-vertical-custom .nav-tabs,
	.col-4,
	.pills-vertical .nav-pills,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.pills-vertical .tab-content,
	.col-9,
	.tab-vertical-custom.tab-content,
	.col-10,
	.col-11,
	.col-12,
	.col,
	.form-wizard .container ol.progtrckr li,
	.col-auto,
	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm,
	.col-sm-auto,
	.col-md-1,
	.col-md-2,
	.col-md-3,
	.form-wizard.form-wizard-vertical .container ol.progtrckr,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.form-wizard.form-wizard-vertical .container .content,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.form-wizard.form-wizard-vertical .container > div:not(.content),
	.col-md,
	.col-md-auto,
	.col-lg-1,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg,
	.col-lg-auto,
	.col-xl-1,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl,
	.col-xl-auto {
		/* position: relative;
		width: 100%; */
		padding-right: 10px !important;
		padding-left: 10px !important;
	}
}
@media (max-width: 380px) {
	.col-1,
	.col-2,
	.col-3,
	.tab-vertical-custom .nav-tabs,
	.col-4,
	.pills-vertical .nav-pills,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.pills-vertical .tab-content,
	.col-9,
	.tab-vertical-custom.tab-content,
	.col-10,
	.col-11,
	.col-12,
	.col,
	.form-wizard .container ol.progtrckr li,
	.col-auto,
	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm,
	.col-sm-auto,
	.col-md-1,
	.col-md-2,
	.col-md-3,
	.form-wizard.form-wizard-vertical .container ol.progtrckr,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.form-wizard.form-wizard-vertical .container .content,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.form-wizard.form-wizard-vertical .container > div:not(.content),
	.col-md,
	.col-md-auto,
	.col-lg-1,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg,
	.col-lg-auto,
	.col-xl-1,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl,
	.col-xl-auto {
		/* position: relative;
		width: 100%; */
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}

select {
	-webkit-appearance: none;
}
/*h1, h2, h3, h4, h5 {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

/*!* loader  *!*/

/*.lds-ellipsis {*/
/*    display: inline-block;*/
/*    width: 80px;*/
/*    height: 80px;*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    top: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/*.lds-ellipsis div {*/
/*    position: absolute;*/
/*    top: 33px;*/
/*    width: 13px;*/
/*    height: 13px;*/
/*    border-radius: 50%;*/
/*    background: #fff;*/
/*    animation-timing-function: cubic-bezier(0, 1, 1, 0);*/
/*}*/

/*.lds-ellipsis div:nth-child(1) {*/
/*    left: 8px;*/
/*    animation: lds-ellipsis1 0.6s infinite;*/
/*}*/

/*.lds-ellipsis div:nth-child(2) {*/
/*    left: 8px;*/
/*    animation: lds-ellipsis2 0.6s infinite;*/
/*}*/

/*.lds-ellipsis div:nth-child(3) {*/
/*    left: 32px;*/
/*    animation: lds-ellipsis2 0.6s infinite;*/
/*}*/

/*.lds-ellipsis div:nth-child(4) {*/
/*    left: 56px;*/
/*    animation: lds-ellipsis3 0.6s infinite;*/
/*}*/

/*@keyframes lds-ellipsis1 {*/
/*    0% {*/
/*        transform: scale(0);*/
/*    }*/
/*    100% {*/
/*        transform: scale(1);*/
/*    }*/
/*}*/

/*@keyframes lds-ellipsis3 {*/
/*    0% {*/
/*        transform: scale(1);*/
/*    }*/
/*    100% {*/
/*        transform: scale(0);*/
/*    }*/
/*}*/

/*@keyframes lds-ellipsis2 {*/
/*    0% {*/
/*        transform: translate(0, 0);*/
/*    }*/
/*    100% {*/
/*        transform: translate(24px, 0);*/
/*    }*/
/*}*/

/*!* Card styling *!*/
/*body {*/
/*    background-color: #2b333e;*/
/*    height: 100vh;*/
/*    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;*/
/*    font-size: 0.875rem;*/
/*    font-weight: normal;*/
/*    line-height: 1.5;*/
/*    color: #141414;*/
/*}*/

/*.header {*/
/*    width: 100%;*/
/*    min-height: 40px;*/
/*    background-color: #211f31;*/
/*    color: #e2e2e2;*/
/*    font-size: 1.5rem;*/
/*    font-weight: bold;*/
/*    text-transform: uppercase;*/
/*    line-height: 1.5;*/
/*}*/

/*.header-container {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    margin: 0 auto;*/
/*    padding: 20px 0;*/
/*    max-width: 1200px;*/
/*}*/

/*.logout {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    text-decoration: none;*/
/*    color: #e2e2e2;*/
/*}*/

/*.logout-title {*/
/*    font-size: 1rem;*/

/*}*/

/*.logout-link, .logo-link {*/
/*    text-decoration: none;*/
/*    color: #e2e2e2;*/
/*}*/

/*.logout-img {*/
/*    margin-right: 10px;*/
/*}*/

/*.container {*/
/*    height: 100vh;*/
/*    max-width: 1200px;*/
/*    margin: 0 auto;*/
/*    margin-top: 60px;*/
/*}*/

/*.head-section {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    flex-direction: row;*/
/*    justify-content: space-between;*/
/*}*/

/*@media screen and (max-width: 476px) {*/
/*    .head-section {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        align-items: center;*/
/*        justify-content: space-between;*/
/*    }*/

/*    .ui.fluid.dropdown {*/
/*        display: block !important;*/
/*        width: auto;*/
/*    }*/

/*    .ui.fluid.dropdown:not(:last-child) {*/
/*        margin-bottom: 10px;*/
/*        margin-right: 0;*/
/*    }*/
/*}*/

/*.ui.fluid.dropdown {*/
/*    display: inline-block;*/
/*    width: auto;*/
/*}*/

/*.ui.fluid.dropdown:not(:last-child) {*/
/*    margin-right: 10px;*/
/*}*/

/*.ui.multiple.dropdown {*/
/*    padding: .22619048em 3.1em .22619048em .35714286em !important;*/
/*}*/

/*.index-title {*/
/*    !*margin: 0 30px 20px 0;*!*/
/*    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;*/
/*    font-size: 2rem;*/
/*    font-weight: bold;*/
/*    text-transform: uppercase;*/
/*    line-height: 2;*/
/*    color: #e2e2e2;*/
/*    text-align: start;*/
/*}*/

/*.index-body {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-around;*/
/*    flex-wrap: wrap;*/
/*}*/

/*.card-wrapper {*/
/*    width: 100%;*/
/*    max-width: 330px;*/
/*    background-color: #e0e0e0;*/
/*    padding: 20px;*/
/*    border-radius: 8px;*/
/*    min-height: 300px;*/
/*    !*box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);*!*/
/*    margin: 30px;*/
/*    margin-bottom: 0;*/
/*}*/

/*.card-container {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/

/*}*/

/*.log-list {*/
/*    list-style-type: none;*/
/*}*/

/*.card-title {*/
/*    margin-bottom: 10px;*/
/*}*/

/*.img-container {*/
/*    position: relative;*/
/*    width: 100%;*/
/*}*/

/*.card-version {*/
/*    position: absolute;*/
/*    top: 5px;*/
/*    right: 5px;*/
/*    color: #fff;*/
/*}*/

/*.card-img {*/
/*    margin-bottom: 10px;*/
/*    width: 100%;*/
/*    max-height: 200px;*/
/*    object-fit: cover;*/
/*    border-radius: 4px;*/

/*}*/

/*.log-item {*/
/*    margin-bottom: 10px;*/
/*    font-size: 1.5rem;*/
/*}*/

/*!* About card styling *!*/

/*.card-body {*/
/*    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;*/
/*    font-size: 1.5rem;*/
/*    line-height: 1.5;*/
/*    color: #141414;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.history-container {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    flex-wrap: wrap;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

/*.history-container .card {*/
/*    margin-right: 25px;*/
/*    margin-left: 25px;*/
/*}*/

/*.card-body h1 {*/
/*    line-height: 2rem;*/
/*    font-size: 2rem;*/
/*    color: #e2e2e2;*/
/*    text-transform: uppercase;*/
/*    font-weight: 700;*/
/*    margin-bottom: 50px;*/
/*}*/

/*.card {*/
/*    margin-bottom: 50px;*/
/*    border-radius: 8px;*/
/*    !*box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);*!*/
/*    background-color: #e2e2e2;*/
/*    padding: 20px;*/

/*}*/

/*.card-link {*/
/*    text-decoration: none;*/
/*    color: #141414;*/
/*}*/

/*.card-image {*/
/*    position: relative;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.card-image img {*/
/*    max-width: 152px;*/
/*    max-height: 152px;*/
/*    text-align: center;*/
/*    width: 100%;*/
/*}*/

/*.card-version {*/
/*    font-size: 2rem;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 0;*/
/*}*/

/*.card-name {*/
/*    margin-bottom: 10px;*/
/*}*/

/*.card-date {*/
/*    margin-bottom: 10px;*/
/*}*/

/*.back-button {*/
/*    text-decoration: none;*/
/*    color: #e2e2e2;*/
/*    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;*/
/*    font-size: 1.5rem;*/
/*    line-height: 1.5;*/
/*    text-align: left;*/
/*}*/

/*.card-info li {*/
/*    list-style-type: none;*/
/*    margin-bottom: 10px;*/
/*}*/

/*@media (max-width: 1200px) {*/
/*    .header-container {*/
/*        max-width: 1000px;*/
/*    }*/

/*    .container {*/
/*        max-width: 1000px;*/
/*    }*/
/*}*/

/*@media (max-width: 992px) {*/
/*    .header-container {*/
/*        max-width: 90%;*/
/*    }*/

/*    .container {*/
/*        max-width: 90%;*/
/*    }*/
/*}*/

/*@media (max-width: 867px) {*/
/*    .header-container {*/
/*        max-width: 90%;*/
/*    }*/

/*    .container {*/
/*        max-width: 90%;*/
/*    }*/

/*    .card-wrapper {*/
/*        max-width: 80%;*/
/*    }*/
/*}*/

/*@media (max-width: 768px) {*/
/*    .header-container {*/
/*        max-width: 90%;*/
/*    }*/

/*    .container {*/
/*        max-width: 90%;*/
/*    }*/

/*    .card-body {*/
/*        width: 100%;*/
/*    }*/

/*    .card {*/
/*        max-width: 80%;*/
/*    }*/

/*    .card-image img {*/
/*        width: 100%;*/
/*        object-fit: cover;*/
/*    }*/

/*    a.button1 {*/
/*        padding: 20px 60px 20px 60px !important;*/
/*    }*/
/*}*/

/*@media (max-width: 467px) {*/
/*    .header-container {*/
/*        max-width: 90%;*/
/*    }*/

/*    .container {*/
/*        max-width: 90%;*/
/*    }*/

/*    .card-wrapper {*/
/*        max-width: 100%;*/
/*        margin: 20px 10px;*/
/*    }*/
/*}*/

/*.load-more_container {*/
/*    width: 100%;*/
/*    text-align: center;*/
/*}*/

/*.load-msg {*/
/*    color: rgb(226, 226, 226);*/
/*    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;*/
/*    font-size: 1.5rem;*/
/*    line-height: 1.5;*/
/*    margin-bottom: 30px;*/
/*}*/

/*.scroll-to-top {*/
/*    position: fixed;*/
/*    bottom: 30px;*/
/*    right: 50px;*/
/*    color: #fff;*/
/*    font-size: 2rem;*/
/*}*/

/*a.button1 {*/
/*    display: inline-block;*/
/*    padding: 0.35em 1.2em;*/
/*    border: 0.1em solid #FFFFFF;*/
/*    !* margin: 2em 0.3em 2.5em 58px; *!*/
/*    margin: 20px 0 50px 0;*/
/*    border-radius: 0.12em;*/
/*    box-sizing: border-box;*/
/*    text-decoration: none;*/
/*    font-family: 'Roboto', sans-serif;*/
/*    font-weight: 300;*/
/*    color: #fff;*/
/*    text-align: center;*/
/*    transition: all 0.2s;*/
/*    cursor: pointer;*/
/*}*/

/*a.button1:hover {*/
/*    color: #000000;*/
/*    background-color: #fff;*/
/*}*/

/*!* login *!*/

/*.login {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    min-height: 100vh;*/
/*}*/

/*.main-container {*/
/*    width: 100%;*/
/*    max-width: 330px;*/
/*    background-color: #fff;*/
/*    padding: 20px;*/
/*    border-radius: 8px;*/
/*    height: 300px;*/
/*    !*box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);*!*/
/*}*/

/*form {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-around;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*}*/

/*input {*/
/*    width: 100%;*/
/*    height: 40px;*/
/*    border-radius: 5px;*/
/*    !*border: 1px solid rgba(0, 0, 0, 0.15);*!*/
/*    text-align: center;*/
/*    !*box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.05);*!*/
/*}*/

/*input::placeholder {*/
/*    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;*/
/*    font-size: 0.875rem;*/
/*    font-weight: normal;*/
/*    line-height: 1.5;*/
/*    color: #868ba1;*/
/*}*/

/*button[type=submit] {*/
/*    width: 100%;*/
/*    height: 40px;*/
/*    border-radius: 5px;*/
/*    !*border: 1px solid rgba(0, 0, 0, 0.15);*!*/
/*    text-align: center;*/
/*    !*box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.05);*!*/
/*    background-color: #343a40;*/
/*    color: #fff;*/
/*    cursor: pointer;*/
/*}*/

/*.checkbox label {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*    width: 50%;*/
/*}*/ ;
