/* Accordions */

.accordion {
  .card {
    margin-bottom: .75rem;
    box-shadow: 0px 1px 7px 1px rgba(255, 255, 255, 0.08);    
    border-radius: .25rem;
    .card-header {
      background-color: transparent;
      border: none;
      padding: 2rem;
      * {
        font-weight: 400;
        font-size: 1rem;        
      }
      button {
        padding: 0;
        display: block;
        width: 100%;
        text-align: left;
        color: inherit;
        text-decoration: none;
        font-size: inherit;
        position: relative;
        @include transition(color .5s ease);
        padding-right: 1.5rem;
        line-height: 24px;
        &:before {
          font-family:"themify";
          position: absolute;
          right: 7px;
          top: 0;
          font-size: 18px;
          display: block;
        }
      }
    }
    .card-body {
      font-size: 14px;
      padding: 0 2rem 2rem 2rem;
      font-weight: 400;
      line-height: 1.5;
      i {
        font-size: 1.25rem;
      }
    }
  }
  &.accordion-bordered {
    box-shadow: 0px 1px 7px 1px rgba(255, 255, 255, 0.08);   
    .card {
      margin: 0 2rem;
      border-top: 1px solid $border-color;
      box-shadow: none;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      .card-header,
      .card-body {
        padding-left: 0;
        padding-right: 0;
      }
      .card-header {
        button {
          &:before {
            color: theme-color(danger);
          }
        }
      }
      &:first-child {
        border-top: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  &.accordion-solid-content {
    .card {
      padding: 0;
      .card-header {
        padding: 0;        
        button {
          padding: 2rem 4.5rem 2rem 2rem;
          @include transition(all .2s linear);
          &:before {
            top: 40%;
            right: 40px;
          }
        }
      }
      .card-body {
        padding: 2rem;
        background: theme-color(success);
        color: $white;
      }
    }
  }
  &.accordion-multi-colored {
    .card {
      .card-header,
      .card-body {
        background: transparent;
        color: $white;
      }
      &:nth-child(1) {
        background: theme-color(info);
      }
      &:nth-child(2) {
        background: theme-color(success);
      }
      &:nth-child(3) {
        background: theme-color(danger);
      }
      &:nth-child(4) {
        background: theme-color(warning);
      }
      &:nth-child(5) {
        background: theme-color(info);
      }
    }
  }
}
